.eventCardImg {
    width: 100%;
    aspect-ratio: 9/12;
    object-fit: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.eventCardImg .centeredImg {
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    /* IE 5-7 */
    filter: alpha(opacity=0);
    /* modern browsers */
    opacity: 0;
}

.eventCard:hover {
    cursor: pointer;
}

.eventCardImg {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.eventCardImgText {
    visibility: hidden;
    position: absolute;
    transition: opacity .2s, visibility .2s;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(31,163,255,.9);
    color: #fff;
    opacity: 0;
}

@media (min-width: 768px) {
.eventCard:hover
    .eventCardImgText {
        visibility: visible;
        opacity: 1;
    }

.eventCard:hover
    .eventCardImg {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
    }
}

.eventCard {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}


.swiper {
    padding-top: 10px;
}