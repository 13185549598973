.eventCardPreviewDark {
    color: whitesmoke !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 1rem;
    border-radius: 1rem;
    background-color: #4d4d4d4d;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.eventPreviewDarkPage {
    font-family: Lato, sans-serif;
}

.eventDialogContainer {
    font-family: Lato, sans-serif;
}

.Buttons button {
    color: whitesmoke !important;
}
.eventTicketsDark {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0.5rem;
    background-color: #4d4d4d4d;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}

.tableTicketsDark th, .tableTicketsDark td{
    color: whitesmoke;
}

.eventTicketsDark input, .eventTicketsDark textarea, .eventTicketsDark select {
    background-color: #4d4d4d4d !important;
    border: solid 1px #595959 !important;
    color: white !important;
    span {
        color: white !important;
    }
}

.closeIconDark {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
}

.closeIconDark:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.closeIconLight {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%;
}

.closeIconLight:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.css-12pstkr-MuiPaper-root-MuiDialog-paper {
    border-radius: 1rem !important;
}

@media (max-width: 455px) {
    .tableDesc {
        max-width: 8rem
    }
    .selectAmmount {
        max-width: 7.5rem !important;
    }
    .eventTicketColumn {
        padding-left: 15px !important;
        padding-right: 10px !important;
    }

    .eventTicketColumn th {
        padding-left: 15px !important;
    }
}

.payment-method-opt {
    padding: 1rem;
    border-radius: 1rem;
    background-color: whitesmoke;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    cursor: pointer;
}

.payment-method-opt input[type="radio"] {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.select-payment-title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.map-container {
    position: relative;
    padding-bottom: 56.25%; /* This is the aspect ratio */
    height: 0;
    overflow: hidden;
}

.map-container iframe,
.map-container object,
.map-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.priceTextTicketEvent {
    display: block;
}

.price-line {
    display: flex;
    align-items: center; /* This will vertically center the text and icon */
    margin: 0; /* Removes default margin from <p> elements if necessary */
    color: whitesmoke;
    opacity: 1;
    font-size: 13px;
    white-space: nowrap;
    overflow-x: auto;
}

.info-icon {
    display: flex; /* Using flex to ensure proper alignment */
    align-items: center; /* Centers the icon vertically */
}

.order-items-summary {
    padding: 1rem;
    border-radius: 1rem;
    background-color: #4d4d4d4d;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
}