.kpi-value {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 0;
}

.kpi-label {
    font-size: 1em;
    margin-bottom: 0;
}

.threeDots {
    border: 0;
    border-radius: 50%;
    padding: 5%;
}

.threeDots:hover {
    cursor: pointer;
    background-color: darkgrey;
}

.titleLabel {
    font-size: 1em;
    font-weight: bold;
}

.titleEvent {
    font-size: 1.2em;
    font-weight: bold;
    line-height: 1.5;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 2em;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.textEvent {
    font-size: 1em;
}

.eventBox {
    border-style: solid;
    border-right-style: none;
    border-top-style: none;
    font-size: 14px;
}

.numberEventBox {
    font-size: 24px;
    font-weight: bold;
    padding: 0;
    margin: 0;
}

.kpi-container {
    border-radius: 1rem;
    color: #333;
    box-shadow: 0 0 0.5rem #ccc;
    overflow: hidden;
    padding: .75rem 0;
}

.download_stats_button_icon {
    opacity: 0.5;
    transition: 0.3s

}

.download_stats_button_icon:hover {
    opacity: 1;
    transition: 0.3s
}
