.items-categ-container {
    padding: 20px 10px;
    background-color: white;
}

.items-categ-title {
    font-size: 20px;
    color: #000;
    line-height: 1.5;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 25px;
    margin: 0 auto 1.3rem;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.items-description {
    font-size: 10px;
    color: #000;
    opacity: 0.5;
    line-height: 1.5;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 30px;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.items-title {
    font-size: 16px;
    color: #000;
    line-height: 1.5;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.items-price {
    font-size: 18px;
    color: #000;
    line-height: 1.5;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 25px;
    margin: 0.7rem auto 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.items-icon {
    width: 100%;     /* Full width of its container */
    height: 100px;   /* Fixed height */
    object-fit: scale-down; /* Scales down the image to fit within the dimensions */
    border-radius: 5px;
    border: 0.7px solid lightgrey;
}

.items-separator {
    margin: 1rem 0;
    border: 0.2px solid lightgrey;
}

.menu-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #303139;
    padding: 0.6rem 1rem 0.5rem;
    color: white;
    max-height: 55px;
}

body::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: env(safe-area-inset-top);
    background-color: #303139; /* Change this to the desired background color */
    z-index: -1;
}

.menu-title {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 30px;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu-title-balance {
    font-size: 16px;
    font-weight: 500;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 20px;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

menu-header-balance-container {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #303139;
    padding: 1.2rem 1rem 0.5rem;
    color: white;
}

.menu-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    max-height: 105px;
}

.menu-body {
    margin-top: 60px;
    overscroll-behavior-x: none;
    overscroll-behavior-y: contain;
}

.menu-body.categories {
    margin-top: 105px;
}

.menu-container {
    overflow: hidden !important;
    -webkit-overflow-scrolling: touch !important;
    overscroll-behavior: none;
    font-family: 'Nunito', sans-serif;
}

.menu-container {
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    min-height: 100vh;
    height: 100%;
}

.menu-container::-webkit-scrollbar {
    display: none;
}

.menu-container {
    overscroll-behavior: none;
    overscroll-behavior-y: contain;
    overscroll-behavior-x: none;
}

.menu-item-quantity-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 0.5rem;
}

.order-qr-subtitle {
    margin: 0;
}

.order-qr-container {
    padding: .5rem;
    margin: 0;
    background-color: white;
    border-radius: 1rem;
    border: 1px solid lightgrey;
}



.animation-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #1e1e1e;
}

.tick-container {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tick {
    stroke-dasharray: 160;
    stroke-dashoffset: 160;
    animation: dash 2s ease-in-out forwards;
    z-index: 9999;
    width: 100px;
    height: 100px;
}

.circle {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #66bb6a;
    animation: expand 2s ease-in-out forwards;
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes expand {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(3);
    }
    100% {
        transform: scale(6);
        opacity: 0;
    }
}

@keyframes fade-out-shrink {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.5);
        opacity: 0;
    }
}

.animation-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: white;
    max-width: 100vw;
    max-height: 100vh;
}

.circle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;
    animation: expand 3s ease-in-out forwards;
}

.circle.green {
    background-color: green;
}

.animation-container.white {
    background-color: white;
    animation: fade-out-shrink 2s ease-in-out forwards;
}


.order-detail {
    margin: 0.5rem 0;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 0.5rem;
}

.order-detail-title {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 30px;
    margin: 0 auto;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order-detail-body-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
}

.order-detail-body-item-p {
    font-size: 16px;
    color: #000;
    line-height: 1.5;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    max-height: 30px;
    margin: 0;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inputError {
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

.order-container {
    margin: 0.5rem 0;
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 0.5rem;
}

.order-img {
    width: 60px;     /* Full width of its container */
    height: 60px;   /* Fixed height */
    object-fit: scale-down; /* Scales down the image to fit within the dimensions */
    border-radius: 5px;
    border: 0.7px solid lightgrey;
}

.order-status-text {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
}

.order-menu-title-text {
    font-size: 16px;
    color: #000;
}

.order-menu-price-text{
    font-size: 12px;
}

.order-date-text {
    font-size: 12px;
    font-weight: normal;
}

.close-session-button-menu-account {
    background-color: #303139;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 0.5rem;
    margin: 0.5rem 0;
    width: 80%;
    text-align: center;
}