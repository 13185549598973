@font-face {
    font-family: "Degular";
    font-weight: normal;
    src: url("fonts/Degular/Degular-Regular.otf") format("opentype");
}

@font-face {
    font-family: "Degular";
    font-weight: bold;
    src: url("fonts/Degular/Degular-Black.otf") format("opentype");
}

@font-face {
    font-family: 'Druk';
    font-weight: bold;
    src: url('fonts/Druk/Druk Family/Druk-Heavy-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'Druk';
    font-weight: normal;
    src: url('fonts/Druk/Druk Family/Druk-Medium-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'Druk Text';
    font-weight: normal;
    src: url('fonts/Druk/Druk Text Family/DrukText-Medium-Trial.otf') format('opentype');
}

@font-face {
    font-family: 'Druk Text';
    font-weight: bold;
    src: url('fonts/Druk/Druk Text Family/DrukText-Heavy-Trial.otf') format('opentype');
}


.flashlink-container {
    display: flex;            /* Use Flexbox */
    justify-content: center;  /* Center horizontally */
    align-items: center;      /* Center vertically */
    flex-direction: column;   /* Stack children vertically */
    height: 100vh;
    background: linear-gradient(135deg, #9F7DFF 0%, #FF8EBA 100%);
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-family: "Degular", sans-serif;
}

.flashlink-title {
    font-size: 24pt;
    font-weight: bold;
    margin: 0;
    font-family: 'Druk Text', sans-serif;
}

.img-link-icon {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: block;
    object-fit: contain;
    object-position: center;
    filter: none;
}

.flashlink-item-container {
    display: flex;
    align-items: center;
    background-color: rgba(255, 142, 186, 0.5);
    border-radius: 12px;
    padding: 10px 5px;
    width: 90vw;
    max-width: 768px;
    margin: 0 auto;
    box-shadow: 0 4px 6px 0 rgba(159, 125, 255, 0.1), 0 1px 3px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
}

.flashlink-item-container:hover {
    background-color: rgba(255, 142, 186, 0.7);
    outline: 2px solid rgba(159, 125, 255, 0.5);
    box-sizing: border-box;
    cursor: pointer;
}

.flashlink-item-container p {
    white-space: nowrap; /* Prevents the text from wrapping */
    overflow: hidden; /* Hides overflow */
    text-overflow: ellipsis; /* Adds an ellipsis to truncated overflow */
    margin: 0; /* Adjusts margin to your preference, remove if unnecessary */
    font-size: 14pt;
}

@media (min-width: 768px) {
    .flashlink-item-container p {
        font-size: 16pt;
    }

}

.flashlink-item-icon img {
    display: block;          /* Block will allow margin auto to center the image */
    margin-left: 5px;
    margin-right: auto;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    border: 1px solid rgba(79, 79, 79, 0.2);
    object-fit: cover;
    object-position: center;
    filter: none;
}

/* Make sure to add this to avoid duplicated class names */
.flashlink-item-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flashlink-link {
    text-decoration: none;
    color: #4f4f4f;
}

.flashlink-link:hover {
    color: #4f4f4f;
}