.account-menu-element {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 0;
    cursor: pointer;
}

.account-menu-element-text {
    font-weight: 700;
    color: black;
    text-transform: uppercase;
    font-size: 0.8rem;
}

.account-menu-element.active {
    border-bottom: 3px solid var(--menu-theme-color);
    color: var(--menu-theme-color);
    border-radius: 0.1rem;
}

.create-event-button {
    margin-top: 5px !important;
    margin-bottom: 10px;
}

@media (min-width: 496px) {
    .create-event-button {
        margin-top: 0 !important;
        margin-bottom: 0;
    }
}