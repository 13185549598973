:root {
    --menu-theme-color: #303139; /* Default active color */
}


.nav-categories-container {
    padding: 0;
    background-color: white;
    align-items: center;
    border-bottom: 1px solid lightgrey;
}

.nav-categories-element {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 0;
    cursor: pointer;
}

.nav-categories-element-text {
    font-weight: 700;
    color: black;
}

.nav-categories-element.active {
    border-bottom: 3px solid var(--menu-theme-color);
    color: var(--menu-theme-color);
    border-radius: 0.1rem;
}

.nav-categories-icon {
    width: 1.2rem;
    height: 1.2rem;
}

.nav-categories-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem;
}



.search-container {
    position: relative;
    width: 40px;
    transition: all 0.2s ease-in-out;
}

.search-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    min-width: 20px;
}

.search-input {
    width: 0;
    height: 32.5px;
    padding: 10px 10px 10px 40px;
    border: none;
    border-radius: 20px;
    background-color: #fff;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    box-sizing: border-box;
}

.search-icon.active {
    left: 32.5px;
    font-size: 16px;
}

.search-input.active {
    width: calc(100% - 80px - 20px);
    margin-left: 10px;
    transition-delay: 0.2s;
}

.search-container.expanded {
    width: 250px;
    transition-delay: 0.2s;
    margin-right: 20px;
}

.search-container.expanded .search-input {
    width: 100%;
    transition-delay: 0.2s;
}

input[type="text"] {
    padding-right: 30px;
}

.clear-input {
    position: absolute;
    top: 47.5%;
    left: 240px;
    transform: translate(-50%, -50%);
    font-size: 20px;
    cursor: pointer;
    transition: all 0.05s ease-in-out;
    min-width: 20px;
    opacity: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
}

.clear-input.active {
    opacity: 1;
}

.react-icon-close {
    color: #4f4f4f;
}

.search-input:focus {
    outline: none;
    border: 1.2px solid black;
}

