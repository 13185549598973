.containerDark {
    background-color: #1a1a1a;
    color: whitesmoke;
}

.fullPageContainer {
    position: relative;
    height: 100vh;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-image: url("/public/assets/images/portada-flashpass-6.jpg");
}

.searchContainer {
    width: 100%;
    max-width: 500px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    overflow: hidden;
}

input[type="text"].inputSearch  {
    background-color: transparent;
    border: 0;
    color: whitesmoke;
}

input[type="text"].inputSearch::placeholder {
    color: whitesmoke;
}

input[type="text"]:focus.inputSearch  {
    outline: none 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    background-color: transparent;
    border: none;
}

#textLema {
    transition: all 0.5s ease;
    font-family: 'Lato', sans-serif;
}

#textLema h1 {
    font-weight: 900;
    margin-bottom: 0.5rem;
    font-size: 4rem;
}

#textLema:hover {
    color: #f5a623;
}

#textLema:hover::after {
    content: "";
    display: block;
    width: 100%;
    height: 2px;
    background-color: #f5a623;
    margin-top: 5px;
}

#textLema::after {
    content: "";
    display: block;
    width: 0%;
    height: 2px;
    background-color: #f5a623;
    margin-top: 5px;
    transition: all 0.5s ease;
}

#goToEvents {
    transition: all .5s ease;
}

.navbarMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    flex-direction: column;
    background-color: #0e1111;
    position: fixed;
    top: -1000px;
    left: -1000px;
    right: -1000px;
    transition: top .4s ease;
    z-index: 101;
}

.navbarMobile a, .navbarMobile .buttonLink {
    color: whitesmoke;
    text-decoration: none;
    margin: 1rem 0;
}

.navbarMobile.active {
    top: 0;
    left: 0;
    right: 0;
}

.searchbarMobile.col {
   opacity: 0;
    display: none;
}

@media (max-width: 768px) {
    .searchbarMobile.col.active .searchContainer {
        opacity: 1;
        visibility: visible;
        transition: all .6s ease;
        animation-name: animate;
        animation-duration: 2s;
        animation-timing-function: ease-in;
        animation-iteration-count: infinite;
    }
}

@media (max-width: 768px) {
    .swiper-button-next, .swiper-button-prev {
        display: none !important;
        opacity: 0 !important;
    }
}

.swiper-button-next, .swiper-button-prev {
    color: grey !important;
    margin: 0 1rem;
}


.navbarDesktop {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    flex-direction: column;
    background-color: #0e1111;
    position: absolute;
    top: -1000px;
    opacity: 0;
    height: 0;
    z-index: 10;
}

.navbarDesktop a {
    color: whitesmoke;
    text-decoration: none;
    margin: 1rem 0;
}

.navbarDesktop.active {
    top: 60px;
    opacity: 1;
    transition: opacity .2s ease;
    overflow: hidden;
    width: 13rem;
    height: fit-content;
}

@media (min-width: 768px) and (max-width: 990px) {
    .navbarDesktop.active {
        left: calc(30.5rem + (100vw - 585px) / 2);
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
    }
}

@media (min-width: 991px) and (max-width: 1199px) {
    .navbarDesktop.active {
        left: calc(38rem + (100vw - 585px) / 2);
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
    }
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .navbarDesktop.active {
        left: calc(44rem + (100vw - 585px) / 2);
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
    }
}

@media (min-width: 1400px) {
    .navbarDesktop.active {
        left: calc(49rem + (100vw - 585px) / 2);
        padding-right: calc(var(--mdb-gutter-x) * 0.5);
        padding-left: calc(var(--mdb-gutter-x) * 0.5);
    }
}

.navbarDesktop a {
    color: whitesmoke;
    text-decoration: none;
    padding: .5rem 0.75rem;
    margin: 0.2rem 0.4rem;
    border-radius: .5rem;
    overflow: hidden;
    transition: all .2s ease;
}

.navbarDesktop a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.8);
}

.navbarDesktop button {
    color: whitesmoke;
    text-decoration: none;
    padding: .5rem 0.75rem;
    margin: 0.2rem 0.4rem;
    border-radius: .5rem;
    overflow: hidden;
    transition: all .2s ease;
    background-color: transparent;
    border: none;
}

.navbarDesktop button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.8);
}