html {
    margin: 0;
    padding: 0;
    background-color: #1a1a1a;
    color: whitesmoke;
    max-width: 100%;
    border: none;
}

body {
    max-width: 100%;
    overflow-x: hidden;
    border: none;
}

.noBorderForThisSelect .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.noBorderForThisSelect:focus-within .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

/* Hide the arrows in WebKit-based browsers (Chrome, Safari, etc.) */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the arrows in Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* Custom SweetAlert styles */
.swal-wide {
  width: 600px !important;
  max-width: 90% !important;
}

.swal2-select.form-select {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  margin: 0.5rem 0;
}

/* Improve form layout in SweetAlert */
.swal2-input.form-control, 
.swal2-textarea.form-control {
  margin: 0.5rem 0 !important;
  width: 100% !important;
}

.form-label {
  margin-bottom: 0.5rem;
  font-weight: 500;
  text-align: left;
  display: block;
}

/* Inventory audit related styles */
.audit-item-row-updated {
  background-color: rgba(76, 175, 80, 0.1);
  transition: background-color 0.5s ease;
}

.audit-item-row-error {
  background-color: rgba(244, 67, 54, 0.1);
}

/* Mobile optimizations for inventory audit screens */
@media (max-width: 768px) {
  .audit-controls-container {
    flex-direction: column;
    align-items: stretch !important;
  }
  
  .audit-controls-container .MuiButton-root {
    margin-bottom: 8px;
    width: 100%;
  }
  
  .audit-table-cell-mobile {
    padding: 8px 4px !important;
  }
  
  .audit-quantity-input .MuiInputBase-root {
    min-width: 80px;
    width: 100%;
  }
  
  .audit-filters-container {
    flex-direction: column;
  }
  
  .audit-filters-container .MuiFormControlLabel-root {
    margin-right: 0;
    margin-bottom: 4px;
  }
  
  /* Adjust table for mobile */
  .MuiTableCell-head {
    font-size: 12px !important;
    padding: 8px 4px !important;
  }
  
  /* Make sure input fields are large enough to tap */
  .MuiTableCell-body .MuiInputBase-root {
    min-height: 42px;
  }
}

/* Tooltip hover effect on expected quantity for better UX */
.expected-quantity-cell:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

/* Animation for updated rows */
@keyframes highlightUpdate {
  0% { background-color: rgba(255, 193, 7, 0.3); }
  100% { background-color: transparent; }
}

.row-updating {
  animation: highlightUpdate 1s ease;
}

/* Improve audit progress visualization */
.audit-progress-bar .MuiLinearProgress-bar {
  transition: transform 0.5s ease-out;
}

/* Improve focus states for better accessibility */
.audit-quantity-input .MuiInputBase-root:focus-within {
  box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.25);
}

/* Stock quantity differences styling */
.quantity-diff-negative {
  color: #f44336;
  font-weight: bold;
}

.quantity-diff-positive {
  color: #2196f3;
  font-weight: bold;
}

.quantity-diff-zero {
  color: #4caf50;
  font-weight: bold;
}

/* Enhanced inventory quantity inputs */
.inventory-quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.inventory-quantity-control .MuiIconButton-root {
  padding: 4px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}

.inventory-quantity-control .MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.inventory-quantity-control .MuiIconButton-root:disabled {
  color: rgba(0, 0, 0, 0.26);
}

/* Fixes for number inputs */
.audit-quantity-input input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.audit-quantity-input input[type="number"]::-webkit-inner-spin-button,
.audit-quantity-input input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* More mobile optimization */
@media (max-width: 768px) {
  .audit-quantity-input .MuiOutlinedInput-root {
    padding-left: 4px;
    padding-right: 4px;
  }
  
  .inventory-quantity-control .MuiIconButton-root {
    min-width: 36px;
    min-height: 36px;
  }
  
  .inventory-quantity-control {
    width: 100%;
  }
  
  /* Increase hit target for buttons */
  .inventory-quantity-control .MuiIconButton-root {
    font-size: 20px;
    font-weight: bold;
  }
  
  /* Ensure the quantity is easily readable */
  .audit-quantity-input input {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}

/* Enhanced inventory quantity input styles */
.inventory-quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
}

.inventory-quantity-control .quantity-control-button {
  font-size: 18px;
  font-weight: bold;
  min-width: 36px;
  min-height: 36px;
  margin: 0 2px;
}

/* Animation effect for when values change */
@keyframes highlightField {
  0% { background-color: rgba(255, 193, 7, 0.4); }
  100% { background-color: transparent; }
}

.inventory-quantity-control .MuiOutlinedInput-root.Mui-focused {
  animation: highlightField 1s ease;
}

/* Mobile optimizations for quantity control */
@media (max-width: 768px) {
  .quantity-control-button {
    font-size: 22px !important;
    font-weight: bold;
  }
  
  /* Ensure the quantity is easily readable on mobile */
  .audit-quantity-input input {
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  
  /* Make touched row more visible */
  .MuiTableRow-root:active {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
